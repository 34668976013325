import React, { createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'

import { APP_CONSTANTS } from 'constants/app'

import { MarkupTable } from './MarkupTable'
import { Filter } from './Filter'
import { Header } from './Header'
import { Modals } from './Modals'

const PAGE_SIZE = 50

function callAPI(method, url, file, smartResult) {
  let result: any = []

  const xhr = new XMLHttpRequest()
  xhr.open(method, url, false)
  xhr.withCredentials = true
  xhr.onload = function () {
    if (xhr.readyState !== 4) {
      return
    }
    if (xhr.status === 200) {
      result = JSON.parse(xhr.responseText)
      if (result.toast) {
        toastr.info('', result.toast)
      }
      if (smartResult && result.data) result = result.data
      if (smartResult && result.results) result = result.results
    } else {
      try {
        const error = JSON.parse(xhr.responseText)
        if (error.toast) {
          toastr.error('Error', error.toast)
        }
      } catch (e) {
        console.error(xhr.statusText)
      }
    }
  }
  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    xhr.send(formData)
  } else {
    xhr.send(null)
  }
  return result
}

function getAPI(url) {
  return callAPI('GET', url, undefined, true)
}

function postFileAPI(url, file) {
  return callAPI('POST', url, file, true)
}

function getMarkupsFromAPI(
  page,
  code,
  city,
  community,
  state,
  markup,
  orderBy,
) {
  return callAPI(
    'GET',
    `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/list_details/?page=${page}&code=${code}&city=${city}&community=${community}&state=${state}&markup=${markup}&order_by=${orderBy}&page_size=${PAGE_SIZE}`, // ds
    undefined,
    false,
  )
}

function exportMarkupAPI(code, city, community, state, markup, orderBy) {
  return getAPI(
    `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/export/?code=${code}&city=${city}&community=${community}&state=${state}&markup=${markup}&order_by=${orderBy}`,
  )
}

export const MarkupPageContext = createContext<any>({
  markups: [],
  setMarkups: () => undefined,
  page: 1,
  setPage: () => undefined,
  pageCount: 0,

  codeFilter: '',
  setCodeFilter: () => undefined,
  cityFilter: '',
  setCityFilter: () => undefined,
  communityFilter: '',
  setCommunityFilter: () => undefined,
  stateFilter: '',
  setStateFilter: () => undefined,
  markupFilter: '',
  setMarkupFilter: () => undefined,
  orderByFilter: '',
  setOrderByFilter: () => undefined,

  communityOptions: [],
  stateOptions: [],
  markupOptions: [],

  exportModalOpen: false,
  setExportModalOpen: () => undefined,
  importModalOpen: false,
  setImportModalOpen: () => undefined,
  postFileAPI: () => undefined,

  setShouldUpdate: () => undefined,
  setShouldUpdateExport: () => undefined,
  resetFilters: () => undefined,
})

export const MarkupPage = () => {
  const [markups, setMarkups] = useState<any>([])
  const [page, setPage] = useState(1)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [shouldUpdateExport, setShouldUpdateExport] = useState(false)
  const [pageCount, setPageCount] = useState(0)

  const [codeFilter, setCodeFilter] = useState('')
  const [cityFilter, setCityFilter] = useState('')
  const [communityFilter, setCommunityFilter] = useState('')
  const [stateFilter, setStateFilter] = useState('')
  const [markupFilter, setMarkupFilter] = useState('')
  const [orderByFilter, setOrderByFilter] = useState('')

  const [communityOptions, setCommunityOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [markupOptions, setMarkupOptions] = useState([])

  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)

  useEffect(() => {
    updateMarkupResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const placeOptions = getAPI(
      `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/place_options/`,
    )
    setMarkupOptions(placeOptions.markups)
    setCommunityOptions(placeOptions.communities)
    setStateOptions(placeOptions.states)
  }, [])

  useEffect(() => {
    if (shouldUpdate) {
      updateMarkupResults()
      setShouldUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate])

  useEffect(() => {
    if (shouldUpdateExport) {
      exportMarkupAPI(
        codeFilter,
        cityFilter,
        communityFilter,
        stateFilter,
        markupFilter,
        orderByFilter,
      )
      setShouldUpdateExport(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateExport])

  function updateMarkupResults() {
    const markupResults = getMarkupsFromAPI(
      page,
      codeFilter,
      cityFilter,
      communityFilter,
      stateFilter,
      markupFilter,
      orderByFilter,
    )
    setPageCount(Math.ceil(markupResults.count / PAGE_SIZE))
    setMarkups(markupResults.results)
  }

  function resetFilters() {
    setCodeFilter('')
    setCityFilter('')
    setCommunityFilter('')
    setStateFilter('')
    setMarkupFilter('')
    setOrderByFilter('')
    setPage(1)
  }

  return (
    <MarkupPageContext.Provider
      value={{
        markups: markups,
        setMarkups: setMarkups,
        page: page,
        setPage: setPage,
        pageCount: pageCount,

        codeFilter,
        setCodeFilter: setCodeFilter,
        cityFilter,
        setCityFilter: setCityFilter,
        communityFilter,
        setCommunityFilter: setCommunityFilter,
        stateFilter,
        setStateFilter: setStateFilter,
        markupFilter,
        setMarkupFilter: setMarkupFilter,
        orderByFilter,
        setOrderByFilter: setOrderByFilter,

        communityOptions,
        stateOptions,
        markupOptions,

        exportModalOpen,
        setExportModalOpen: setExportModalOpen,
        importModalOpen,
        setImportModalOpen: setImportModalOpen,
        postFileAPI: postFileAPI,

        setShouldUpdate: setShouldUpdate,
        setShouldUpdateExport: setShouldUpdateExport,
        resetFilters: resetFilters,
      }}
    >
      <Helmet>
        <title>{I18n.t('pageTitles.markup')}</title>
      </Helmet>
      <Header />
      <Filter />
      <MarkupTable />
      <Modals />
    </MarkupPageContext.Provider>
  )
}
