import classNames from 'classnames'
import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import {
  CERTIFICATE_STATES,
  CERTIFICATE_TYPES,
} from 'components/certificate/constants'
import { InputNumber } from 'components/common/InputNumber'
import { StaticCombobox } from 'components/common/StaticCombobox'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { FilterReset } from '../FilterReset'

import connector from './connector'

class CertificateFilter extends Component {
  static propTypes = {
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    isLoading: bool,
    length: number,
    resetForm: func.isRequired,
    showCustomerNumber: bool,
    values: shape({
      type: string,
      empto_external_number: oneOfType([number, string]),
      status: string,
    }).isRequired,
  }

  static defaultProps = {
    isLoading: false,
    length: 0,
    showCustomerNumber: false,
  }

  state = {
    currentSelectedCertificateState: {},
    currentSelectedCertificateType: {},
  }

  componentDidUpdate({ values: prevValues }) {
    const { values } = this.props

    if (this.props.values !== prevValues) this.handleValueChange(this.props)

    if (values.type !== prevValues.type) {
      this.setState({
        currentSelectedCertificateType: getCurrentValueObject(
          this.getSelectionItems().CERTIFICATE_TYPES,
          values.type,
        ),
      })
    }

    if (values.status !== prevValues.status) {
      this.setState({
        currentSelectedCertificateState: getCurrentValueObject(
          this.getSelectionItems().CERTIFICATE_STATES,
          values.status,
        ),
      })
    }
  }

  getSelectionItems = () => ({
    CERTIFICATE_STATES: [
      ...resetInputOption,
      ...CERTIFICATE_STATES.map(item => ({
        value: `${item.id}`,
        label: `${I18n.t(item.name)}`,
      })),
    ],
    CERTIFICATE_TYPES: [
      ...resetInputOption,
      ...CERTIFICATE_TYPES.map(item => ({
        value: `${item.id}`,
        label: `${I18n.t(item.name)}`,
      })),
    ],
  })

  handleValueChange = props => {
    if (props.values) {
      if (props.showCustomerNumber) {
        const requestValues = {
          company__empto_external_number: props.values.empto_external_number,
          status: props.values.status,
          type: props.values.type,
        }
        props.getCurrentFilterValues(requestValues)
        props.getCertificates(null, requestValues)
      } else {
        const requestValues = {
          status: props.values.status,
          type: props.values.type,
        }
        props.getCurrentFilterValues(requestValues)
        props.getMyCertificates(null, requestValues, props.orderBy)
      }
    }
  }

  render() {
    const { handleBlur, handleChange, resetForm, showCustomerNumber, values } =
      this.props

    return (
      <>
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <FilterReset
            onResetFilter={() => {
              resetForm()
            }}
            showResetFilterButton={
              values.empto_external_number || values.status || values.type
            }
          />
        </ButtonBar>
        <div
          className={classNames(
            'uk-grid',
            'uk-child-width-1-1',
            { 'uk-child-width-1-2@m': !showCustomerNumber },
            { 'uk-child-width-1-3@m': showCustomerNumber },
          )}
        >
          <StaticCombobox
            label={I18n.t('filterTranslations.certificateFilter.docType')}
            name='type'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().CERTIFICATE_TYPES}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedCertificateType}
          />

          <StaticCombobox
            label={I18n.t('filterTranslations.certificateFilter.status')}
            name='status'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().CERTIFICATE_STATES}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedCertificateState}
          />

          {showCustomerNumber && (
            <InputNumber
              label={I18n.t(
                'filterTranslations.certificateFilter.customerNumber',
              )}
              maxLength={15}
              name='empto_external_number'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.empto_external_number}
            />
          )}
        </div>
      </>
    )
  }
}

export default connector(CertificateFilter)
